
import {defineComponent} from "vue";
import CardcashbackEsports from "@/components/CardCashbackEsports/CardCashbackEsports.vue"

export default defineComponent({
        name: "Home",
        components: {
            CardcashbackEsports
        },
        
        data() {
            return {
                features: ["€122 WELCOME BONUS", "24/7 SUPPORT", "WIDE ARRAY OF ESPORT"]
            }

        }
});
