
import { defineComponent, PropType } from "vue";
import { Thumbnail, API_CustomEnumeration } from "@/types";
export default defineComponent({
  props: {
    bonus: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    features: {
      type: Object as PropType<API_CustomEnumeration>,
      required: true,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
  },
});
