import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-324785da")
const _hoisted_1 = { class: "container container-characteristics" }
const _hoisted_2 = { class: "container container-slider-cards" }
const _hoisted_3 = { class: "container container-cards-long" }
const _hoisted_4 = { class: "container-call-to-action" }
const _hoisted_5 = { class: "container container-bonuses" }
const _hoisted_6 = { class: "section-faq fluid-container hide-on-mobile" }
const _hoisted_7 = { class: "container container-grid" }
const _hoisted_8 = { class: "container container-partners" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_SectionCharacteristicsEsports = _resolveComponent("SectionCharacteristicsEsports")!
  const _component_CardGamesSlider = _resolveComponent("CardGamesSlider")!
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_SectionCardLongEsports = _resolveComponent("SectionCardLongEsports")!
  const _component_CallToActionSection = _resolveComponent("CallToActionSection")!
  const _component_SubtitleSections = _resolveComponent("SubtitleSections")!
  const _component_SectionBonusesEsport = _resolveComponent("SectionBonusesEsport")!
  const _component_SectionFaqHorseBetting = _resolveComponent("SectionFaqHorseBetting")!
  const _component_SectionHomeGridNews = _resolveComponent("SectionHomeGridNews")!
  const _component_CarouselPartners = _resolveComponent("CarouselPartners")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "position-relative main-header",
            style: _normalizeStyle({
          'background-image':
            'url(' +
            require('../assets/images/demosimages/background-esports-betting.jpeg') +
            ')',
        })
          }, [
            _createVNode(_component_main_header, {
              class: "content-header",
              title: "Make Selection\r\n                    For Placing an eSport",
              description: "Upto $250 Bonus to split however you like new customers only*",
              href: "header.button.href",
              buttonName: "JOIN COINFLIP NOW"
            })
          ], 4),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_SectionCharacteristicsEsports)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_CardGamesSlider, { items: _ctx.items }, null, 8, ["items"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SectionHeading, {
              class: "section-heading",
              title: "Let The Games Begin!",
              subtitle: "",
              description: "Experience the Best in Esports Betting at Coinflip"
            }),
            _createVNode(_component_SectionCardLongEsports)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_CallToActionSection, {
              thumbnail: { url: 'images/demosimages/logo-coinflip-esports.png' },
              title: "A Platform for eSports",
              description: "Dress with crossover V-neckline with ruffled trims.opns Features & longslee sleeves with ruffled trims, elastic cuffs, a cinched waist ruffled hem loreming ipsum dolor amet. Lorem ipsum dolor amet."
            }, null, 8, ["thumbnail"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_SubtitleSections, {
              class: "casinos-title",
              title: "Cashback Bonuses",
              subtitle: "At Coinflip, we show only the best casinos"
            }),
            _createVNode(_component_SectionBonusesEsport)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_SectionFaqHorseBetting, { thumbnail: {url:'images/demosimages/faq-esports.png'} }, null, 8, ["thumbnail"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_SectionHomeGridNews)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_CarouselPartners, { items: _ctx.partners }, null, 8, ["items"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}