
import {defineComponent} from "vue";

import CharacteristicsEsports from "@/components/CharacteristicsEsports/CharacteristicsEsports.vue";
// import SectionHeading from "@/components/SectionHeading/SectionHeading.vue";
    export default defineComponent({
        name: "Home",
        components: {

            CharacteristicsEsports
            // SectionHeading,
       

        }
    });
