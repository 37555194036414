import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6b4274f0")
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CasinoCardLong = _resolveComponent("CasinoCardLong")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CasinoCardLong, {
        thumbnail: { url: 'images/misc/feature_casino4.jpg' },
        title: _ctx.title,
        rating: _ctx.rating,
        restrictedCountries: _ctx.restrictedCountries,
        features: _ctx.features,
        href: '/'
      }, null, 8, ["thumbnail", "title", "rating", "restrictedCountries", "features"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CasinoCardLong, {
        thumbnail: { url: 'images/misc/feature_casino4.jpg' },
        title: _ctx.title,
        rating: _ctx.rating,
        restrictedCountries: _ctx.restrictedCountries,
        features: _ctx.features,
        href: '/'
      }, null, 8, ["thumbnail", "title", "rating", "restrictedCountries", "features"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CasinoCardLong, {
        thumbnail: { url: 'images/misc/feature_casino4.jpg' },
        title: _ctx.title,
        rating: _ctx.rating,
        restrictedCountries: _ctx.restrictedCountries,
        features: _ctx.features,
        href: '/'
      }, null, 8, ["thumbnail", "title", "rating", "restrictedCountries", "features"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CasinoCardLong, {
        thumbnail: { url: 'images/misc/feature_casino4.jpg' },
        title: _ctx.title,
        rating: _ctx.rating,
        restrictedCountries: _ctx.restrictedCountries,
        features: _ctx.features,
        href: '/'
      }, null, 8, ["thumbnail", "title", "rating", "restrictedCountries", "features"])
    ])
  ], 64))
}