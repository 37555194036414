import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d4ec9454")
const _hoisted_1 = { class: "d-flex justify-content-between bonuses-section" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardcashbackEsports = _resolveComponent("CardcashbackEsports")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CardcashbackEsports, {
        class: "",
        bonus: 100,
        type: "Cashback Bonuses",
        features: _ctx.features
      }, null, 8, ["features"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CardcashbackEsports, {
        class: "",
        bonus: 100,
        type: "Cashback Bonuses",
        features: _ctx.features
      }, null, 8, ["features"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CardcashbackEsports, {
        class: "",
        bonus: 100,
        type: "Cashback Bonuses",
        features: _ctx.features
      }, null, 8, ["features"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CardcashbackEsports, {
        class: "",
        bonus: 100,
        type: "Cashback Bonuses",
        features: _ctx.features
      }, null, 8, ["features"])
    ])
  ]))
}