
import { defineComponent } from "vue";

import MainHeader from "../components/MainHeader/MainHeader.vue";
import SectionCharacteristicsEsports from "@/components/SectionCharacteristicsEsports/SectionCharacteristicsEsports.vue";
import CardGamesSlider from "@/components/CardGameSlider/CardGamesSlider.vue";
import SectionHeading from "@/components/SectionHeading/SectionHeading.vue";
import CallToActionSection from "@/components/CallToActionSection/CallToActionSection.vue";
import SectionHomeGridNews from "@/components/SectionHomeGridNews/SectionHomeGridNews.vue";
import SectionBonusesEsport from "@/components/SectionBonusesEsport/SectionBonusesEsport.vue";
import SubtitleSections from "@/components/SubtitleSections/SubtitleSections.vue";
import CarouselPartners from "@/components/CarouselPartners/CarouselPartners.vue";
import SectionFaqHorseBetting from "@/components/SectionFAQHorseBetting/SectionFaqHorseBetting.vue";
import SectionCardLongEsports from "@/components/SectionCardLongEsports/SectionCardLongEsports.vue";
export default defineComponent({
  name: "Home",
  components: {
    MainHeader,
    SectionCharacteristicsEsports,
    CardGamesSlider,
    SectionHeading,
    CallToActionSection,
    SectionHomeGridNews,
    SectionBonusesEsport,
    SubtitleSections,
    CarouselPartners,
    SectionFaqHorseBetting,
    SectionCardLongEsports,
  },
  setup() {
      document.title = 'Esports Betting - Coinflip'
  },
  data() {
    return {
      items: [
        {
          title: "Over 1000 eSports",
          description: "The Chilling PvP Strategy Mayhem",
          thumbnail: {
            url: "images/demosimages/card-game-1.jpeg",
          },
        },
        {
          title: "Over 1000 eSports",
          description: "The Chilling PvP Strategy Mayhem",
          thumbnail: {
            url: "images/demosimages/card-game-2.jpeg",
          },
        },
        {
          title: "Over 1000 eSports",
          description: "The Chilling PvP Strategy Mayhem",
          thumbnail: {
            url: "images/demosimages/card-game-3.jpeg",
          },
        },
        {
          title: "Over 1000 eSports",
          description: "The Chilling PvP Strategy Mayhem",
          thumbnail: {
            url: "images/demosimages/card-game-4.jpeg",
          },
        },
        {
          title: "Over 1000 eSports",
          description: "The Chilling PvP Strategy Mayhem",
          thumbnail: {
            url: "images/demosimages/card-game-5.jpeg",
          },
        },
      ],
      partners: [
        "images/partners/client_1.png",
        "images/partners/client_02.png",
        "images/partners/client_3.png",
        "images/partners/client_4.png",
        "images/partners/client_5.png",
      ],
      elements: [
        {
          question: "Which Plan is good for me ?",
          answer:
            "Trends, vision dominates a lot of our subconscious interpretation of the world around us. On top it, pleasing images create better user experience.    ",
        },
        {
          question: "Do I have to commit to a program",
          answer:
            "Trends, vision dominates a lot of our subconscious interpretation of the world around us. On top it, pleasing images create better user experience.    ",
        },
        {
          question: "What Payment Methods are Available?",
          answer:
            "Trends, vision dominates a lot of our subconscious interpretation of the world around us. On top it, pleasing images create better user experience.    ",
        },
      ],
    };
  },
});
