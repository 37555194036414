import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CharacteristicsEsports = _resolveComponent("CharacteristicsEsports")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_CharacteristicsEsports, {
              thumbnail: { url: 'images/demosimages/icon-esport-betting1.png' },
              title: "Esports",
              subtitle: "Opt in & bet on any markets"
            }, null, 8, ["thumbnail"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_CharacteristicsEsports, {
              thumbnail: { url: 'images/demosimages/icon-esport-betting1.png' },
              title: "Esports",
              subtitle: "There is no limit of spending"
            }, null, 8, ["thumbnail"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_CharacteristicsEsports, {
              thumbnail: { url: 'images/demosimages/icon-esport-betting1.png' },
              title: "Esports",
              subtitle: "Free Bets will be credite at 24"
            }, null, 8, ["thumbnail"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}